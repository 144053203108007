import jQuery from "jquery";

window.$ = window.jQuery = jQuery;

try {
    const splitUrl = window.location.href.split('?');
    if (splitUrl.length > 1 && splitUrl[1].indexOf('relogin') === -1) {
        const baseUrl = splitUrl[0];
        window.history.replaceState('name', '', baseUrl);
    }
} catch (e) {

}

$(document).ready(() => {

    function getCookie(cname) {
        const name = cname + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    $.ajaxSetup({
        beforeSend: function (xhr, settings) {
            if (settings.type === 'POST' || settings.type === 'GET') {
                if (!(/^http:.*!/.test(settings.url) || /^https:.*/.test(settings.url))) {
                    xhr.setRequestHeader('X-XSRF-TOKEN', getCookie('XSRF-TOKEN'));
                }
            }
        }
    });

    $('form').on('submit', function (e) {

        const isRelogin = typeof $(this).data('relogin') !== 'undefined';

        if (isRelogin) {
            e.preventDefault();

            const data = $(this).serialize();

            const xhr = $.ajax({
                type: 'POST',
                url: $(this).attr('action'),
                data: data,
                error: function (response) {
                    console.log("error");
                },
                success: function () {
                    window.close();
                },
            });
        }
    });
});
